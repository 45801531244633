import React, { useState } from 'react';
import './confirmation.css';
import See from './See.jpg'; 
import axios from 'axios'; // Установите axios: npm install axios

const accountSid = 'ACddcbe4c5ea031273c5d1dfc0434ec87e'; // Замените на ваш Account SID от Twilio
const authToken = 'f5f09233e744f023d77399069ac9120a'; // Замените на ваш Auth Token от Twilio
const twilioPhoneNumber = '+13862733459'; // Замените на ваш номер телефона от Twilio
const recipientPhoneNumber = '+37433958395'; // Замените на номер телефона получателя

const telegramLink = "https://t.me/+G4Pfgt8wSEVmYzYy";

const WeddingRSVP = () => {
  const [name, setName] = useState('');
  const [guests, setGuests] = useState([{ name: '', wish: '' }]);
  const [attendance, setAttendance] = useState('');
  const [message, setMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleGuestChange = (index, field, value) => {
    const newGuests = [...guests];
    newGuests[index][field] = value;
    setGuests(newGuests);
  };

  const handleAddGuest = () => {
    setGuests([...guests, { name: '', wish: '' }]);
  };

  const handleAttendanceChange = (e) => {
    setAttendance(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const rsvpData = {
      name,
      guests,
      attendance,
    };
    console.log('RSVP Data:', rsvpData);

    // Формируем сообщение и отправляем SMS
    const guestNames = guests.map(guest => guest.name).join(', ');
    const messageBody = `RSVP received from ${name}. Guests: ${guestNames}. Attendance: ${attendance}.`;

    // Отправка SMS напрямую из клиентской части (для демонстрации, не рекомендуется для продакшена)
    axios.post(`https://api.twilio.com/2010-04-01/Accounts/${accountSid}/Messages.json`,
      new URLSearchParams({
        Body: messageBody,
        From: twilioPhoneNumber,
        To: recipientPhoneNumber
      }), {
        headers: {
          'Authorization': `Basic ${btoa(`${accountSid}:${authToken}`)}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then(response => {
        console.log('SMS sent successfully:', response.data);
        setMessage('RSVP sent successfully!');
        setFormSubmitted(true); // Устанавливаем флаг, что форма отправлена
        window.location.href = telegramLink; // Перенаправляем на Telegram канал
      })
      .catch(error => {
        console.error('Error sending RSVP:', error);
        setMessage('Failed to send RSVP.');
      });
  };

  return (
    <div className="wedding-rsvp-container">
      <img src={See} alt="Wedding" className="wedding-photo" />
      {!formSubmitted ? (
        <form className="form" onSubmit={handleSubmit}>
          <div className="form-group">
            <h3 className="title-con">Please let us know about your presence at the event before 10/08/2024.</h3>
            <div className="form-group">
              <label>Guest Names and Wishes:</label>
              {guests.map((guest, index) => (
                <div key={index} className="guest">
                  <input
                    type="text"
                    className="guest-input"
                    placeholder="Guest Name"
                    value={guest.name}
                    onChange={(e) => handleGuestChange(index, 'name', e.target.value)}
                    required
                  />
                  <textarea
                    className="guest-textarea"
                    placeholder="Guest's Wish"
                    value={guest.wish}
                    onChange={(e) => handleGuestChange(index, 'wish', e.target.value)}
                    rows={4}
                    required
                  />
                </div>
              ))}
            </div>
            <div className="form-group">
              <label>Attendance:</label>
              <div className="radio-group">
                <label>
                  <input
                    type="radio"
                    value="будем"
                    checked={attendance === 'будем'}
                    onChange={handleAttendanceChange}
                    required
                  />
                  Will definitely attend
                </label>
                <label>
                  <input
                    type="radio"
                    value="не можем"
                    checked={attendance === 'не можем'}
                    onChange={handleAttendanceChange}
                    required
                  />
                  Unfortunately, cannot attend
                </label>
              </div>
            </div>
            <button type="submit" className="submit-button">Submit</button>
            {message && <p>{message}</p>}
          </div>
        </form>
      ) : (
        <p className="thank-you-message">Thank you!</p>
      )}
    </div>
  );
};

export default WeddingRSVP;
