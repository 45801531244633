import React from 'react';

import Post from './Post.jpg'
import './calendar.css'

function Calendar() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="header-content">
        <div className="text-overlay">
        <div className="h1">We are waiting for</div>
          <div className="h2">You</div>

          </div>
          <img src={Post} alt="Photo" className="photo" />
          
        </div>
        <div className="date-container">
          <hr className="horizontal-line left-line" />
          <div className="date">24</div>
          <div className="date">08</div>
          <div className="date">24</div>
          <hr className="horizontal-line right-line" />
          
        </div>
      </header>
    </div>
  );
}

export default Calendar;
