import React, { useState, useEffect } from 'react';
import './timer.css';
import NIK8951 from './NIK8951.jpg'; // 

const Timer = () => {
    const calculateTimeLeft = () => {
        const difference = +new Date('2024-08-24T00:00:00') - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    return (
        <div className="timer-background" style={{ backgroundImage: `url(${NIK8951})` }}>
            <div className="timer-content">
                <div className="timer-digits">
                    {Object.keys(timeLeft).map(interval => (
                        <div key={interval} className="timer-digit">
                            {timeLeft[interval]}
                        </div>
                    ))}
                </div>
                <div className="timer-labels">
                    <div className="timer-label">Days</div>
                    <div className="timer-label">Hours</div>
                    <div className="timer-label">Minutes</div>
                    <div className="timer-label">Seconds</div>
                </div>
            </div>
        </div>
    );
};

export default Timer;
