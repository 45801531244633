
import image from '../Daily/Black and White Minimalist Wedding Timeline Planner.jpg'; 
import './style.css';

function Daily() {
  return (
    <div className="container">
      <div className="centered-image" >
      <img src={image} alt="centered" className="animated-image" />
      </div>
    </div>
  );
}

export default Daily;