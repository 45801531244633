import React from 'react';
import './profile.css';
import portraits from './portraits.jpg';

const MyComponent = () => {
  return (
    <div className="my-component">
      <div className="image-container">
        <img src={portraits} alt="Portraits" />
      
      </div>
    </div>
  );
};

export default MyComponent;
