import React from 'react';
import './App.css';
import Abaut from './component/Abaut';
import Calendar from './component/ Calendar';
import Daily from './component/Daily';
import Timer from './component/Timer/';
import Join from './component/Join/';
import Confirmation from './component/Confirmation/';
import Profile from './component/Profile'


function App() {
  return (
    <div>
     <Profile/>
      <Abaut/>
      <Calendar/>
      <Timer/>
      <Join/>
      <Daily/>
      <Confirmation/>  
    </div>
  );
}

export default App;

