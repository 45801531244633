import React from 'react';
import './abaut.css';
import Revision from './Revision.jpg';

const About = () => {
    return (
        <div className="about-container">
            <img src={Revision} alt="Фото" className="about-photo" />
            <div className="about-content">
            <h1 className="about-title"> <span className="highlight">The </span> Start of Our Forever</h1>

                <p className="about-text">
                    <span className="highlight">We </span>   invite you to join us in celebrating our love and commitment to one another. This website will serve as your guide to all the details of our special day, from the ceremony to the reception and everything in between.

Thank you for being a part of our journey and for your love and support. We can’t wait to celebrate this joyous occasion with you.
                </p>
            </div>
        </div>
    );
}

export default About;

