import React from 'react';
import './join.css';
import Instagrampost from './Instagrampost.jpg';

function App() {
  return (
    <div className="Join">
      <header className="Join-header">
        <div className="Join-photo-container">
          <img src={Instagrampost} className="Join-photo" alt="Instagrampost" />
        </div>
        <span className="Join-title">
        We look forward to seeing you at 15:00 at St. Amenaprkich Church. The wedding ceremony will be followed by a banquet at Zemlyanka restaurant complex
        </span>
      </header>
    </div>
  );
}

export default App;

